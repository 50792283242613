<template>
    <el-form class="login-form" status-icon :rules="loginRules" ref="loginForm" :model="loginForm" label-width="0">
        <el-form-item prop="Phone">
            <el-input size="large" @keyup.enter.native="handleLogin" v-model="loginForm.Phone" auto-complete="off" placeholder="请输入手机号">
                <i slot="prefix" class="el-icon-mobile-phone" />
            </el-input>
        </el-form-item>
        <el-form-item prop="Code">
            <el-input size="large" @keyup.enter.native="handleLogin" v-model="loginForm.Code" auto-complete="off" placeholder="请输入验证码">
                <i slot="prefix" class="el-icon-chat-line-square" />
                <el-button slot="suffix" style="background-color: #409eff;color: white;" :disabled="disabledBtn" @click="GetSmsCode">{{buttonText}}</el-button>
            </el-input>
        </el-form-item>
        <el-radio-group v-model="ClientID" style="margin: 10px">
<!--            <el-radio label="Admin">管理员</el-radio>-->
            <el-radio label="Enterprise">企业机构</el-radio>
            <el-radio label="Mediator">调解机构</el-radio>
            <el-radio label="Community">社会组织</el-radio>
        </el-radio-group>
        <el-form-item>
            <el-button type="primary" size="small" :loading="loading" @click.native.prevent="handleLogin" class="login-submit">登录</el-button>
            <el-link type="primary" style="float: left" @click="onIndex">< 返回首页</el-link>
            <el-link type="primary" style="float: right" @click="onRegister">免费注册 ></el-link>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
    name: 'phonelogin',
    data() {
        var validatorPhone = function (rule, value, callback) {
            if (value === '') {
                callback(new Error('手机号不能为空'))
            } else if(!(/^1[3456789]\d{9}$/.test(value))){
                callback(new Error('手机号格式错误'))
            } else {
                callback()
            }
        }
        return {
            ClientID: '',

            loginForm: {
                Phone: '',
                Code: ''
            },
            loading: false,
            loginRules: {
                // Phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
                Code: [
                    { required: true, message: '请输入短信验证码', trigger: 'blur' },
                    { min: 6, max: 6, message: '请输入正确的6位数短信验证码', trigger: 'blur' },
                ],
                Phone: [
                    { required: true, validator: validatorPhone, trigger: 'blur' }
                ]
            },

            disabledBtn: false,
            totalTime: 60,
            buttonText: "发送验证码"
        }
    },
    mounted() {
        let currClientID = this.$Util.GetStore("CurrentClientID")
        this.ClientID = this.$Util.IsNull(currClientID) ? "Enterprise" : currClientID === "Admin" ? "Enterprise" : currClientID
    },
    methods: {
        //发送短信
        GetSmsCode(){
            let phoneNum = this.loginForm.Phone
            // let reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
            let reg = 11 && /^1[3456789]\d{9}$/;

            if(this.$Util.IsNull(phoneNum)){
                this.$Util.MsgBox('请输入手机号码', -1)
            }
            else if(!reg.test(phoneNum)){
                this.$Util.MsgBox('手机格式不正确', -1)
            }
            else{
                if(this.totalTime === 60){
                    this.disabledBtn = true
                    this.onStartTimer()

                    let param = {
                        Phone: phoneNum,
                        ClientID: this.$Config.api.clientID + this.ClientID,
                    }
                    //发送登录验证码
                    this.$API.SendLoginSms(this.ClientID + "User", param,resp => {
                        this.$Util.MsgBox(resp.Message, resp.Status)
                    })
                }
            }
        },

        //计时
        onStartTimer() {
            let clock =  window.setInterval(() => {
                this.buttonText = this.totalTime + 's后重新发送';
                this.totalTime --;
                if(this.totalTime < 0){
                    this.disabledBtn = false

                    this.totalTime = 60;
                    this.buttonText = "重新发送验证码";
                    window.clearInterval(clock);
                }
            }, 1000);
        },

        onIndex(){
            this.$router.push( { name: 'index'} )
        },
        onRegister(){
            this.$router.push( { name: 'register'} )
        },

        // 登录
        handleLogin() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.loading = true
                    let param = {
                        Phone: this.loginForm.Phone,
                        Code: this.loginForm.Code,
                        ClientID: this.$Config.api.clientID + this.ClientID,
                    }
                    //验证码用户登录
                    this.$API.SmsLogin(this.ClientID + "User", param, (resp) => {
                        this.$router.replace(this.$Util.loginStore(this.ClientID, resp.Data))
                    })
                }
            })
        }
    }
}
</script>

<style></style>
