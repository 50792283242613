<template>
    <el-form class="login-form" status-icon :rules="loginRules" ref="loginForm" :model="loginForm" label-width="0">
        <el-form-item prop="Account">
            <el-input size="large" @keyup.enter.native="handleLogin" v-model="loginForm.Account" auto-complete="off" placeholder="请输入账号">
                <i slot="prefix" class="el-icon-mobile-phone" />
            </el-input>
        </el-form-item>
        <el-form-item prop="Password">
            <el-input size="large" @keyup.enter.native="handleLogin" :type="passwordType" v-model="loginForm.Password" auto-complete="off" placeholder="请输入密码">
                <i class="el-icon-view el-icon-chat-line-square" slot="suffix" @click="showPassword"></i>
                <i slot="prefix" class="icon-mima" />
            </el-input>
        </el-form-item>
        <el-radio-group v-model="ClientID" style="margin: 10px">
            <el-radio label="Admin">管理员</el-radio>
<!--            <el-radio label="Enterprise">企业机构</el-radio>-->
<!--            <el-radio label="Mediator">调解机构</el-radio>-->
<!--            <el-radio label="Community">社会组织</el-radio>-->
        </el-radio-group>
        <el-form-item>
            <el-button type="primary" size="small" :loading="loading" @click.native.prevent="handleLogin" class="login-submit">登录</el-button>
            <el-link type="primary" style="float: left" @click="onIndex">< 返回首页</el-link>
<!--            <el-link type="info" style="float: right" @click="onRegister">免费注册 ></el-link>-->
        </el-form-item>
    </el-form>
</template>

<script>
export default {
    name: 'adminlogin',
    data() {
        return {
            ClientID: '',
            loginForm: {
                // Account: '18029360516',
                // Password: '666666'
                Account: '',
                Password: ''
            },
            loading: false,
            loginRules: {
                Account: [{ required: true, message: '请输入账号', trigger: 'blur' }],
                Password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, message: '密码长度最少为6位', trigger: 'blur' }
                ]
            },
            passwordType: 'password'
        }
    },
    mounted() {
        this.ClientID = "Admin"
    },
    methods: {
        onIndex(){
            this.$router.push( { name: 'index'} )
        },
        onRegister(){
            this.$router.push( { name: 'register'} )
        },
        showPassword() {
            this.passwordType === '' ? (this.passwordType = 'password') : (this.passwordType = '')
        },
        handleLogin() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    let param = {
                        Account: this.loginForm.Account,
                        Password: this.loginForm.Password,
                        ClientID: this.$Config.api.clientID + this.ClientID,
                    }

                    this.$API.AccountLogin(this.ClientID + "User", param, (resp) => {
                        this.$router.replace(this.$Util.loginStore(this.ClientID, resp.Data))
                    })
                }
            })
        }
    }
}
</script>

<style></style>
