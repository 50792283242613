<template>
    <div class="login-container">
<!--        <el-image src="https://www.fszjfy.gov.cn/res/t3/fsfy/img/fs_logo.png" style="position: fixed;top: 80px;left: 100px"/>-->

        <div class="login-weaper">
            <el-tabs v-model="activeName" type="border-card" style="height: 400px;width: 382px">
                <h4 class="login-title">
                    {{ config.title }}
                </h4>
                <el-tab-pane label="账号登录" name="first">
                    <adminlogin />
                </el-tab-pane>
<!--                <el-tab-pane label="短信登录" name="second">-->
<!--                    <phoneLogin />-->
<!--                </el-tab-pane>-->
            </el-tabs>
        </div>
        <div class="bottom-fixed">
            <div class="bottom-version">版本号 © {{config.version}}</div>
        </div>
    </div>
</template>

<script>
import userLogin from './userlogin'
import phoneLogin from './phonelogin'
import Adminlogin from "./adminlogin";

export default {
    name: 'login',
    components: {Adminlogin, userLogin,phoneLogin},
    mounted() {
        this.$Util.ResetRouter()
    },
    data() {
        return {
            time: '',
            config: this.$Config.login,
            activeName: 'first',
        }
    }
}
</script>

<style lang="scss">
::-webkit-scrollbar {
    width: 0 !important;
}
::-webkit-scrollbar {
    width: 0 !important;height: 0;
}

.login-container {
    position: relative;
    width: 100%;
    height: 100%;
    background: url("https://erp.miaosou.club/files/zhizhi/image/login_bg.png");
    background-size: cover;
}
.login-weaper {
    //margin: 0 auto;
    //width: 1000px;
    //position: relative;
    //float: right;
    //width: 400px;
    position: absolute;
    right: 0;
    margin: auto;
    padding: 40px;
    top: 0;
    bottom: 0;
    height: 500px;

    //box-shadow: -4px 5px 10px rgba(0, 0, 0, 0.4);
    .el-input-group__append {
        border: none;
    }
}

.login-left,
.login-border {
    position: relative;
    min-height: 500px;
    align-items: center;
    display: flex;
}
.login-left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    justify-content: center;
    flex-direction: column;
    background-color: #409EFF;
    color: #fff;
    float: left;
    width: 50%;
    position: relative;
}
.login-left .img {
    width: 140px;
}
.login-time {
    position: absolute;
    left: 25px;
    top: 25px;
    width: 100%;
    //color: #fff;
    color: #76838f;
    font-weight: 200;
    opacity: 0.9;
    font-size: 18px;
    overflow: hidden;
}
.login-left .title {
    margin-top: 60px;
    text-align: center;
    color: #fff;
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 25px;
}

.login-border {
    //border-left: none;
    //border-top-right-radius: 5px;
    //border-bottom-right-radius: 5px;
    border-radius: 10px;

    color: #fff;
    background-color: #fff;
    //width: 50%;
    width: 100%;
    float: left;
    box-sizing: border-box;
}
.login-main {
    margin: 0 auto;
    width: 65%;
    //box-sizing: border-box;
}
.login-main > h3 {
    margin-bottom: 20px;
}
.login-main > p {
    color: #76838f;
}
.login-title {
    color: #333;
    margin: 20px;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    letter-spacing: 4px;
}
.login-version {
    color: #333;
    //margin-bottom: 40px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    letter-spacing: 2px;
}
.login-menu {
    margin-top: 40px;
    width: 100%;
    text-align: center;
    a {
        color: #999;
        font-size: 12px;
        margin: 0px 8px;
    }
}
.login-submit {
    width: 100%;
    height: 45px;
    border: 1px solid #409EFF;
    background: none;
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 300;
    color: #409EFF;
    cursor: pointer;
    margin-top: 30px;
    font-family: "neo";
    transition: 0.25s;
}
.login-form {
    margin: 10px 0;
    i {
        color: #333;
    }
    .el-form-item__content {
        width: 100%;
    }
    .el-form-item {
        margin-bottom: 12px;
    }
    .el-input {
        input {
            padding-bottom: 10px;
            text-indent: 5px;
            background: transparent;
            border: none;
            border-radius: 0;
            color: #333;
            border-bottom: 1px solid rgb(235, 237, 242);
        }
        .el-input__prefix {
            i {
                padding: 0 5px;
                font-size: 16px !important;
            }
        }
    }
}
.login-code {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 0 0 10px;
}
.login-code-img {
    margin-top: 2px;
    width: 100px;
    height: 38px;
    background-color: #fdfdfd;
    border: 1px solid #f0f0f0;
    color: #333;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 5px;
    line-height: 38px;
    text-indent: 5px;
    text-align: center;
}


.bottom-fixed{
    font-weight: bold;
    color: white;
    font-size: large;
    z-index: 100;
    width: 100%;
    position: fixed;
    bottom:1%;
    .bottom-version{
        text-align: center;
    }
}

</style>
